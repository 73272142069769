import React from 'react';
import Container from '@material-ui/core/Container';

function Placeholder() {
  return (
    <Container maxWidth="sm">
      <Container><h1>Riskieren</h1></Container>
      <img src="/assets/placeholder.jpg" alt="Placeholder" Style="max-width: 100%;"/>
      <a Style="float:right;background-color:black;color:white;text-decoration:none;padding:4px 6px;font-family:-apple-system, BlinkMacSystemFont, &quot;San Francisco&quot;, &quot;Helvetica Neue&quot;, Helvetica, Ubuntu, Roboto, Noto, &quot;Segoe UI&quot;, Arial, sans-serif;font-size:12px;font-weight:bold;line-height:1.2;display:inline-block;border-radius:3px" href="https://unsplash.com/@fairfilter?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge" target="_blank" rel="noopener noreferrer" title="Download free do whatever you want high-resolution photos from Oliver Roos">
        <span Style="display:inline-block;padding:2px 3px">Photo By: Oliver Roos</span>
      </a>
      <Container>
      <br/>
      <br/>
      <br/>
      Byte by byte, more will come!
      </Container>
    </Container>
  );
}

export default Placeholder;
